import { createRouter, createWebHistory } from 'vue-router';

const routes = [
    { path: '/', component: () => import("@/views/IndexView.vue") },
    { path: '/:pathMatch(.*)*', redirect: '/' }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;