<template>
  <div class="container">
    <div class="logo">
      <router-link to="/">
        <img src="@/assets/logo.png">
      </router-link>

      <h3>药店商采业务优化增利系统</h3>
    </div>
  </div>
</template>

<script>

export default {
  name: "HeaderView",
};
</script>

<style scoped>

.container {
  background-color: #ffffff;
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;

  .logo {
    margin-left: 15%;
    display: flex;
    align-items: center;

    h3 {
      color: #0074fd;
      font-size: 1.6rem;
      margin-left: 10px;
    }
  }
}
</style>
