<template>
  <div class="common-layout">
    <el-container>

      <el-header style="--el-header-padding:0">
        <el-affix :offset="0">
          <Header></Header>
        </el-affix>
      </el-header>

      <el-main style="--el-main-padding:0; margin-top: 10px">
        <router-view />
      </el-main>

      <el-footer class="footer">
        <Footer></Footer>
      </el-footer>
    </el-container>

  </div>
</template>
<script>

import Header from './components/layout/HeaderView.vue'
import Footer from './components/layout/FooterView.vue'

export default {
  name: 'App',
  components: {
    Header,
    Footer,
  }
}
</script>

<style>
body {
  margin: 0;
  padding: 0;
  background-color: #f5f5f6;
}

footer {
  position: fixed;
  width:100%;
  bottom: 0;
}
</style>
