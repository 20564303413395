<template>
  <div class="footer">
    <p>
      Copyright 2022-{{currentYear}} © 力美康 版权所有
      <a href="https://beian.miit.gov.cn" target="_blank">京ICP备2022018937号-1</a>
    </p>
  </div>
</template>

<script>

export default {
    name: "footerView",

    data() {
      return {
        // 初始化当前年份
        currentYear: new Date().getFullYear()
      };
    },
}
</script>

<style>
.footer {

  height: 60px;
  color: #cccccc;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 60px;

  a {
    text-decoration: none;
    color: #cccccc;
  }
}
</style>